<template>
  <div>
    <div>
      <div class="container mx-auto lg:flex mb-10">
        <div class="lg:w-1/2 p-28 lg:pl-20 text-center lg:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
            v-html="$t('ext.welcome.firefox.title')"
          ></h2>
          <p
            class="
              text-xl text-gray-500
              dark:text-gray-400
              mb-10
              lg:w-2/3
              w-full
            "
          >
            {{ $t("ext.welcome.firefox.subtitle") }}
          </p>
        </div>
        <div class="md:w-1/2 p-10 md:p-20">
          <img
            :src="require(`@/assets/images/ext-preview-${currentLocale}.png`)"
          />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 overflow-hidden">
      <div class="container mx-auto mb-10 p-20">
        <div class="flex lg:flex-nowrap flex-wrap items-center relative mb-20">
          <div
            class="
              lg:w-2/5
              w-full
              ml-auto
              lg:text-left
              text-center
              lg:mb-0
              mb-10
            "
          >
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.firefox.content.title-1") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.firefox.content.article-1") }}
            </p>
          </div>
          <img
            class="lg:w-3/5 w-full lg:pl-20 flex justify-end relative z-20"
            :src="require(`@/assets/images/overview-tab-${$i18n.locale}.png`)"
            alt="depth"
          />
        </div>

        <div
          class="
            flex
            lg:flex-nowrap
            flex-wrap-reverse
            relative
            z-20
            items-center
            mb-20
          "
        >
          <img
            class="lg:w-3/5 w-full lg:pr-20 relative z-20"
            :src="require(`@/assets/images/traffic-tab-${$i18n.locale}.png`)"
            alt="traffic"
          />
          <div class="lg:w-2/5 w-full lg:text-left text-center lg:mb-0 mb-10">
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.firefox.content.title-2") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.firefox.content.article-2") }}
            </p>
          </div>
        </div>

        <div class="flex flex-wrap lg:flex-nowrap relative z-10 items-center">
          <div
            class="
              lg:w-2/5
              w-full
              ml-auto
              text-center
              lg:text-left lg:mb-0
              mb-10
            "
          >
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.firefox.content.title-3") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.firefox.content.article-3") }}
            </p>
          </div>
          <img
            class="lg:w-3/5 w-full lg:pl-20 flex justify-end relative z-20"
            :src="require(`@/assets/images/geo-tab-${$i18n.locale}.png`)"
            alt="depth"
          />
        </div>
      </div>
    </div>
    <TrustedExt />
  </div>
</template>

<script>
import { Trans } from "@/plugins/Translation";
import TrustedExt from "@/components/TrustedExt.vue";
export default {
  components: {
    TrustedExt,
  },
  computed: {
    currentLocale() {
      return Trans.currentLocale;
    },
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {},
};
</script>
